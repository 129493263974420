import http from '@/utils/http';

export function PostBlog(params){
	return http.post('/v1/posts',params)
}
//获取所有贴文---后续添加分页参数
export function GetBlog(pageNo,pageSize){
    return http.get(`/v1/posts?pageNo=${pageNo}&pageSize=${pageSize}`)
}
//获取用户自己的贴文列表
export function GetUserBlog(pageNo,pageSize){
    return http.get(`/v1/posts/user?pageNo=${pageNo}&pageSize=${pageSize}`)
}
//获取指定用户的贴文列表
export function GetVUserBlog(params,pageNo,pageSize){
    return http.get(`/v1/posts/user/${params}?pageNo=${pageNo}&pageSize=${pageSize}`)
}

export function DeleteBlog(params){
	return http.delete(`/v1/posts/${params}`)
}
//获取贴文详情
export function GetBlogDetails(params){
    return http.get(`/v1/posts/${params}`)
}
export function RefreshBlog(params,postId){
    return http.put(`/v1/posts/${postId}`,params)
}