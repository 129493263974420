import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router/index'

const service = axios.create({ 
	withCredentials:false, 
	timeout: 10 * 1000, 
	headers: {"content-type": "application/json" }
})

// 请求拦截器
service.interceptors.request.use(config => {
	config.headers.Authorization = localStorage.getItem('token') || '';
	config.headers['Content-type'] = 'application/json';
	return config;
})
// 相应拦截器
service.interceptors.response.use(response => {
	if(response.status == 200){
		return response.data
	}
}, error => {
	if(error.response.status == 422){
		if(error.response.data.code == '未来某个需要处理页面跳转啥的code'){
			//do something
		}else{
			//do something
			Message({
				message: error.response.data.message,
				type: 'error'
			})
		}
 		
	}else if(error.response.status == 401){
		Message({
			message: error.response.data.message,
			type: 'error'
		})
		sessionStorage.clear()
		router.replace({
			path:'/'
		})
	}else{
		Message({
			message: error.response.data.message,
			type: 'error'
		})

	}
   
})

export default service;
