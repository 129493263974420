<template>
  <div class="BannerAritcle">
    <div class="TypeSelect">
        过滤器（暂无）
    </div>
    <div class="Item" v-for="(item,index) in userbloglist" :key="index" @click="BrowserBlog(item.id)">
        <div class="UpItem">
            <div class="Title">{{item.title}}</div>
            <div class="Time">{{item.createTime}}</div>
        </div>
        <div class="MiddleItem">
            <div class="Tags" v-for="(itemtag,index) in item.keywords" :key="index">{{itemtag}}</div>
        </div>
        <div class="DownItem">
            <div v-if="!item.hasLike">
                <div class="ReadCount" @click.stop="_isLike(item.id)">
                    <i class="iconfont icon-dianzan" ></i>
                    <span class="count">{{item.likeCount}}</span>赞
                </div>
            </div>
            <div v-else>
                <div class="ReadCount" @click.stop="_unLike(item.id)">
                    <i class="iconfont icon-dianzan1" ></i>
                    <span class="count">{{item.likeCount}}</span>赞
                </div>
            </div>
            
            <div class="Comments"><i class="el-icon-chat-dot-square"></i><span class="count">{{item.commentCount}}</span></div>
            <div class="Browser" @click.stop="BrowserBlog(item.id)">浏览</div>
        </div>
    </div>
    <div class="Footer">
        <el-button size="mini" class="PreBtn" :disabled="preable" @click="PreItem">上一页</el-button>
        <el-button size="mini" class="NextBtn" :disabled="nextable" @click="NextItem">下一页</el-button>
    </div>
  </div>
</template>

<script>
import { isLike,unLike } from '../../../api/like';
import { GetVUserBlog} from '../../../api/blog';
export default {
    name:'VArticle',
    props:['id'],
    data(){
        return{
            userbloglist:[],
            pageNo:1,
            pageSize:5,

            count:1,
            preable:true,
            nextable:false
        }
    },
    created(){
        this.GetBlog()
    },
    mounted(){

    },
    methods:{
        PreItem(){
            this.count -= 1
            if(this.count == 1){
                this.preable = true
                this.nextable = false
                this.pageNo = this.count
                GetVUserBlog(this.id,this.pageNo,this.pageSize).then(res=>{
                    this.userbloglist = res.data.list
                })
            }else{
                this.nextable = false
                this.pageNo = this.count
                GetVUserBlog(this.id,this.pageNo,this.pageSize).then(res=>{
                    this.userbloglist = res.data.list
                   
                    
                })
            }
        },
        NextItem(){
            this.count += 1
            this.preable = false
            this.pageNo = this.count
            GetVUserBlog(this.id,this.pageNo,this.pageSize).then(res=>{
                this.userbloglist = res.data.list
                if(this.userbloglist.length == this.pageSize){
                    this.nextable = false
                }else{
                    this.nextable = true
                }
            })
        },
        async _isLike(id){
            let res = await isLike(id)
            this.$message({
                message: '点赞成功',
                type: 'success'
            });
            this.GetBlog()
        },
        async _unLike(id){
            let res = await unLike(id)
            this.$message({
                message: '取消点赞成功',
                type: 'success'
            });
            this.GetBlog()
        },
        async GetBlog(){
            let res= await GetVUserBlog( this.id, this.pageNo,this.pageSize)
            this.userbloglist = res.data.list
        },
        BrowserBlog(id){
            this.$router.push({
                path:`/article/details/${id}`
            })
        }
        
    }
}
</script>

<style scoped>
.BannerAritcle{
    position: relative;
    width: 100%;
}
.TypeSelect{
  height: 30px;
  width: 100%;
  padding: 5px 0;
  background-color: #b8b8b8;
  font-size: 14px;
  line-height: 30px;
}
.Item{
    padding: 20px 0px 10px 0;
    border-bottom:1px dotted #ddd;
}
.Item:hover{
    background-color: rgb(238, 238, 238);
}
.UpItem{
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0px 5px 10px 10px;
}
.Title{
    font-size: 18px;
    height: 20px;
    line-height: 20px;
    text-align: left;
    flex:  0 0 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.Title:hover{
    color: #f76400;
    cursor: pointer;
}
.Time{
    flex: 1;
    text-align: right;
    height: 20px;
    font-size: 13px;
    line-height: 20px;
    color:grey;
}
.MiddleItem{
    height: 100%;
    padding: 0px 5px 10px 10px;
    display: flex;
    align-items: center;
}
.Tags{
    height: 12px;
    width: auto;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #999aaa;
    line-height: 12px;
    padding: 4px;
    margin-right: 20px;
    border-radius: 3px;
}
.DownItem{
    position: relative;
    height: 20px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
}
.ReadCount ,.Comments{
    height: 20px;
    width: auto;
    padding: 0px 5px;
    line-height: 20px;
    font-size: 15px;
    color:grey;
    cursor: pointer;
}
.ReadCount:hover{
    color: rgb(172, 170, 170);
}
.count{
    padding: 0px 5px;
}
/* .Edit{
    position: absolute;
    right: 66px;
    height: 20px;
    font-size: 15px;
    line-height: 20px;
    padding: 0px 5px;
    cursor: pointer;
    font-weight: 600;
} */
.Browser{
    position: absolute;
    right: 10px;
    height: 20px;
    font-size: 15px;
    line-height: 20px;
    padding: 0px 5px;
    cursor: pointer;
    font-weight: 600;
}

.Footer{
    display: flex;
    height: 40px;
    background: white;
    align-items: center;
}
.PreBtn{
    position: absolute;
    left: 10px;
}
.NextBtn{
    position: absolute;
    right: 10px;
}
</style>